(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name inscription.inscribedTeams.controller:InscribedTeamsCtrl
     *
     * @description
     *
     */
    angular
      .module('neo.home.phases.phase.inscribedTeams')
      .controller('InscribedTeamsCtrl', InscribedTeamsCtrl);

  function InscribedTeamsCtrl(InscribedTeams, categories, genders, sports, towns, $filter) {
      var vm = this;
      vm.ctrlName = 'InscribedTeamsCtrl';
      vm.data = {
        title: 'PHASES.INSCRIBED_TEAMS',
        notFoundMessage: 'RESULT_NOT_FOUND',
        headers: [
          {id: 'team', name: 'TEAM', canOrder: true, orderBy: 'teamOrder'},
          {id: 'sport', name: 'SPORT', canOrder: true},
          {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
          {id: 'gender', name: 'GENDER', canOrder: true},
          {id: 'entity', name: 'ENTITY', canOrder: false},
          {id: 'town', name: 'TOWN', canOrder: false},
          {id: 'championship', name: 'CHAMPIONSHIPS.CHAMPIONSHIP', canOrder: true}
        ],
        /*        state: {
         //TODO
         slink: ''
         },*/
        defaultOrderBy: ['championship', 'sport', 'categoryOrder', 'gender', 'team'],
        defaultTypeOrderBy: [true, false, true],
        values: parseTeams(InscribedTeams),
        areRowsClickable: false
      };

      function parseTeams(data) {
        //parser de les dades per a omplir la llista
        var inscribedTeams = [];
        angular.forEach(data, function (inscribedTeam) {
          var category = findItemById(categories, inscribedTeam.teamCategoryId);
          var newInscribedTeam = {
            team: inscribedTeam.teamName,
            teamOrder: $filter('lowercase')(inscribedTeam.teamName),
            sport: $filter('translate')('SPORTS.' + findItemById(sports, inscribedTeam.teamSportId).name),
            category: $filter('translate')('CATEGORIES.' + category.name),
            categoryOrder: category.startYear,
            gender: $filter('translate')(findItemById(genders, inscribedTeam.teamGenderId).name),
            entity: inscribedTeam.entityName,
            town: getTownName(inscribedTeam.entityTownId),
            championship: isInscribedToChampionship(inscribedTeam.hasJoinedAChampionship)
          };
          inscribedTeams.push(newInscribedTeam);
        });
        return inscribedTeams;
      }

    function findItemById(collection, itemId) {
        return _.find(collection, function (i) {
          return i.id === itemId;
        });
      }

    function getTownName(entityTown) {
        var item = findItemById(towns, entityTown);
      if (angular.isUndefined(item)) {
        return '-'
      }
        return item.name;
      }

    function isInscribedToChampionship(hasJoined) {
        if (hasJoined === false) {
          return $filter('translate')('CHAMPIONSHIPS.NOT_ASSIGNED');
        }
        else {
          return $filter('translate')('CHAMPIONSHIPS.ASSIGNED');
        }
      }
    }
}());
